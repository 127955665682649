<template>
    <div class="wrap">
        <div class="modiBox">
            <h2>제목</h2>
            <input type="text" v-model="boards.tit">
            <h2>내용</h2>

            <textarea name="" id="" cols="30" rows="25" v-model="boards.desc"></textarea>
        </div>
        <div @click="modiMethod()" class="modiBt">수정</div>
    </div>
</template>

<script>
    export default {
        props: ['idx'],
        metaInfo() {
            return {
                title: '우성소프트',
                titleTemplate: `%s | 묻고 답하기`, // title 뒤에 붙일 공통된 이름 (주로 사이트이름) 
                meta: [{
                        name: 'description',
                        content: `30년전 우성소프트의 사명(社名)은 우성의 프로그램을 사용하는 모든 고객의 성공을 염원하며 만들어 졌습니다. 30년간 1000개가 넘는 고객사의 선택과 만족, 신뢰를 원동력으로 고객의 성공이 회사의 사명(使命)이 되었습니다.`
                    },
                    {
                        name: 'keyword',
                        content: `PLS, pls 판매기록, 우성소프트,우성컴퓨터, 지도, 작물보호제, 기업 역량, 회사 연혁`
                    },
                ],
            }
        },
        data() {
            return {
                login: false,
                Standard: 15,
                //vuex로 로그인 관리 묻고답하기
                boardMode: true,
                writeMode: false,
                file: '',
                //true 문의 게시판 false 문의하기
                limit: 10,
                start: 0,
                boards: ''
            }
        },
        created() {
            const BaseData = "admin/wsapi/qna/"
            this.$Axios.get(BaseData+this.idx)
                .then((result) => {
                    this.boards = result.data.result[0];
                    console.log(this.boards);
                    // console.log(this.idx);
                })
        },
        methods: {
           modiMethod(){
               let UpdateData = {
                idx:this.boards.idx,
                tit:this.boards.tit,
                desc:this.boards.desc,
            }
            // console.log(UpdateData);
                this.$Axios.put(`admin/wsapi/qna/update1`,UpdateData)
                .then((result)=>{
                    if(result.data.query == 'ok'){
                        alert("수정되었습니다");
                        this.$router.push({path:'/wsboard/qna'});
                    }
                })
           }
        }
    }
</script>

<style lang="scss">
    @import './table.scss';
</style>

<style lang="scss" scoped>
    .wrap{
        padding-top:70px;
        .modiBox{
            
            text-align:left;
            margin:0;
            input{
                
                width:90%;
                height:50px;
                font-size:20px;
            }
            textarea{
                width:90%;
                font-size:16px;
                 resize: none;
            }
        }
        .modiBt{
            width:100px;
            height:50px;
            background-color: rgb(113, 135, 138);
            border-radius:10px;
            line-height: 50px;
            text-align:center;
            margin:20px 0;
            color:#fff;
        }
    }
    div.board h2 {
    padding-top:20px;
    padding-bottom:5px;
    margin:0;
    font-weight: bold;
    text-align: left;
    }
</style>