<template>
    <section class='section1 zoom'>
        <passwordQna v-if="!check" v-bind:password='board.password' @child="parent" />
        <div class='wrap' v-else>
            <h2>묻고 답하기</h2>
            <div class="container">

                <router-link tag='div' :to="'/wsboard/qna/modi/'+idx" class="modiBox">수정</router-link>
                <div class="deleteBox" @click="deleteMethod()">삭제</div>
            </div>

            <div class='zoom_table'>
                <div class='head'>
                    
                    <h4>{{board.tit}}</h4>
               
                    <p>
                           작성일 : {{$moment(board.date).format('YYYY-MM-DD')}}    작성자 : {{board.writer}}</p>
                </div>
                <div class='desc'>
                    <p v-html="board.desc"></p>
                    <div class='answer'>
                        <h3 class='b_text'>답변</h3>
                        <div v-if="board.answer!=null" v-html="board.answer"></div>
                        <p v-else>답변 대기 중 입니다.</p>
                    </div>
                </div>
            </div>
            <router-link tag='div' to="/wsboard/qna" class='btn'>목록</router-link>
        </div>
    </section>
</template>

<script>
    import passwordQna from './password-qna';
    export default {
        props: ['idx'],
        components: {
            passwordQna
        },
        created() {
            let idx = this.idx;
            const BaseData = `admin/wsapi/qna/${idx}`
            this.$Axios.post(BaseData, {
                    idx
                })
                .then((result) => {
                    this.board = result.data.result[0];
                    this.mode = 'load'
                })
        },
        data() {
            return {
                board: {
                    no: 0,
                    cate: '',
                    tit: "",
                    desc: "",
                    recive: "",
                    status: "",
                    date: '',
                },
                    check:false
            }
        },
        methods: {
            parent() {
                // this.board.private = 0
                this.check = true;
            },
            deleteMethod(){
               
               let idx= this.idx;
                 if (!confirm("삭제하시겠습니까?")) {
                        alert("삭제 취소하셨습니다");
                    } else {
                        this.$Axios.post(`admin/wsapi/qna/delete`,{idx})
                        .then((result)=>{
                            if(result.data.query == 'ok'){
                                alert("삭제되었습니다.");
                                this.$router.push({path:'/wsboard/qna'});
                            }else{
                                alert("삭제되지 않았습니다.")
                            }
                        })
                    }
           }
        }
    }
</script>
<style lang="scss">
@import './table.scss';
div.btn{
    margin-right: 5px;
}
    
</style>

<style lang="scss" scoped>

.zoom_table {
    .head {
        padding:0px;
        h4{
            padding:30px 20px;
            font-size:30px;
            color:#000;
            font-weight: bold;
        
    }
    p{
        text-align: right;
        padding-right:50px;
    }
}
}
    .wrap{
        .container{
            display:flex;
            justify-content: flex-end;
            div{
                width:100px;
                height:40px;
                display:flex;
                justify-content: center;
                align-items:center;
                border-radius:10px;
                margin:10px;
                text-align:center;
                color:#fff;
                
            }
            .modiBox{
                cursor:pointer;
                background-color:rgb(78, 119, 146);
            }
            .deleteBox{
                cursor:pointer;
                background-color:rgb(56, 56, 56);
            }

            .modiBox:hover ,.deleteBox:hover{
                
                background-color:rgb(175, 194, 186);
                color:#000;
            }
            
            
        }
    }
    
</style>